import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { OnClickAction } from './toggleAction'
const { Button, Popover, Menu, toaster, Alert } = require('evergreen-ui')
import { navigate } from '@reach/router'

class BulkOperation extends React.Component<any> {
  public state = {
    isShowPdf: false,
    isPdf: false,
    isExcel: false,
    isCanopy: false,
    bulkDownloadData: [],
  }
  private displayMenu = () => {
    return (
      <Popover
        position={'BOTTOM_LEFT'}
        content={
          <Menu>
            <Menu.Group>
              <Menu.Item>
                <Button
                  onClick={this.downloadPdf}
                  appearance="minimal"
                  marginBottom={12}
                  isLoading={this.state.isPdf}
                >
                  PDF DOWNLOAD
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  onClick={this.downloadExcel}
                  appearance="minimal"
                  marginBottom={12}
                  isLoading={this.state.isExcel}
                >
                  EXCEL DOWNLOAD
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  onClick={this.downloadCanopy}
                  appearance="minimal"
                  isLoading={this.state.isCanopy}
                >
                  CANOPY UL DOWNLOAD
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  onClick={this.downloadAll}
                  appearance="minimal"
                  isLoading={this.state.isCanopy}
                >
                  {`PDF & Excel & Canopy UL download `}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button onClick={this.bulkCancellation} appearance="minimal">
                  BULK TASK CANCEL
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button onClick={this.bulkRestart} appearance="minimal">
                  BULK TASK RESTART
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button onClick={this.bulkConversionRestart} appearance="minimal">
                  BULK CONVERSION RESTART
                </Button>
              </Menu.Item>
            </Menu.Group>
          </Menu>
        }
      >
        <Button appearance="primary">BULK ACTIONS</Button>
      </Popover>
    )
  }

  private bulkOperation = (navigateUrl: string, navigateParams: any = undefined) => {
    if (this.props.bulkData.length > 0) {
      navigate(navigateUrl, navigateParams)
    } else {
      toaster.warning('Please select at least one item ')
    }
  }
  private bulkRestart = () => {
    this.bulkOperation('/bulktaskrestart/')
  }

  private bulkConversionRestart = () => {
    this.bulkOperation('/bulktaskconversionrestart/')
  }

  private bulkCancellation = () => {
    this.bulkOperation('/bulktaskcancellation/')
  }

  private downloadPdf = async () => {
    this.bulkOperation('/bulkdownload/', {
      state: {
        isPdfDownload: true,
        isExcelDownload: false,
        isCanopyDownload: false,
        isDownloadAll: false,
      },
    })
  }

  private downloadExcel = async () => {
    this.bulkOperation('/bulkdownload/', {
      state: {
        isPdfDownload: false,
        isExcelDownload: true,
        isCanopyDownload: false,
        isDownloadAll: false,
      },
    })
  }
  private downloadAll = async () => {
    this.bulkOperation('/bulkdownload/', {
      state: {
        isPdfDownload: false,
        isExcelDownload: false,
        isCanopyDownload: false,
        isDownloadAll: true,
      },
    })
  }
  private downloadCanopy = async () => {
    this.bulkOperation('/bulkdownload/', {
      state: {
        isPdfDownload: false,
        isExcelDownload: false,
        isCanopyDownload: true,
        isDownloadAll: false,
      },
    })
  }

  public render() {
    return <>{this.displayMenu()}</>
  }
}

const downloadDispatch = (dispatch: any) => {
  return bindActionCreators(
    {
      OnClickAction,
    },
    dispatch,
  )
}

const mapStateToProps = (storeData: any) => {
  return {
    bulkData: storeData.onCheckReducer.bulkOperationData,
  }
}

export default connect(mapStateToProps, downloadDispatch)(BulkOperation)
