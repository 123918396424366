import * as React from 'react'
import styled from 'styled-components'
import { parserGetLog } from '../../api/parser-log-api'
import { colors } from '../../utils/colors'
import TitleCard from '../TitleCard/TitleCard'
const { Button, Combobox, DownloadIcon, toaster } = require('evergreen-ui')
var wrap = require('word-wrap')

const FORMATTED_LOGS = 'Formatted Logs - All'
const RAW_LOGS = 'Raw Logs'
const block_matcher = new RegExp("Page (\\d*)-Extraction-(Started|Completed)")
const display_mode_matcher = new RegExp("Formatted Logs - Page(\\d*)")

class ParamsLogContainer extends React.Component<any> {
  public state = {
    parserText: [],
    logsByLines: [],
    logsByBlocks: {},
    logDisplayModes: [RAW_LOGS, FORMATTED_LOGS],
    selectedLogDisplayMode: FORMATTED_LOGS,
    logs: '',
  }
  public componentDidMount() {
    const taskId = this.props.params
    parserGetLog(taskId).then((result: any) => {
      if (result) {
        const parserText: any[] = []
        const logsByBlocks: any = {}
        const logDisplayModes = this.state.logDisplayModes
        const logsByLines = result.split('\n')
        let currentExtractionPage: string | any = null
        for (let i = 0; i < logsByLines.length; i++) {
          let item = logsByLines[i]
          let txtColor = ''
          let subItems = item.split(' - ')
          if (subItems.length >= 4) {
            let startIndex = 0
            const logLevel = subItems[2]
            const message = subItems[3]
            let isHtml = false

            switch (logLevel) {
              case 'INFO':
                txtColor = 'lightskyblue'
                break;
              case 'WARN':
              case 'WARNING':
                txtColor = 'orange'
                break;
              case 'ERROR':
                txtColor = 'red'
                break;
              case 'HTML':
                isHtml = true
                startIndex = 3
                break;
              default:
                break;
            }

            const displayString = subItems.splice(startIndex).join(' - ')
            const parsedItem = { 'color': txtColor, 'displayString': displayString, isHtml: isHtml }
            const match = block_matcher.exec(message)
            if (match) {
              let pageNumber = match[1]
              let borderType = match[2]
              if (pageNumber === currentExtractionPage) {
                if (borderType == 'Completed') {
                  logsByBlocks[currentExtractionPage].push(parsedItem);
                  currentExtractionPage = null
                }
              } else {
                if (borderType == 'Started') {
                  currentExtractionPage = pageNumber
                  logsByBlocks[pageNumber] = []
                }
              }
            }
            parserText.push(parsedItem);
            if (currentExtractionPage != null) {
              logsByBlocks[currentExtractionPage].push(parsedItem);
            }
          }
        }
        const pages = Object.keys(logsByBlocks)
        if (pages.length > 0) {
          pages.sort((x, y) => Number(x) - Number(y))
          for (let i = 0; i < pages.length; i++) {
            const displayMode = 'Formatted Logs - Page' + pages[i]
            logDisplayModes.push(displayMode)
          }
        }
        this.setState({
          parserText: parserText,
          logsByLines: logsByLines,
          logsByBlocks: logsByBlocks,
          logDisplayModes: logDisplayModes,
          logs: result,
        })

      } else {
        const msg = 'Not available'
        this.setState({
          parserText: [msg],
          logs: msg,
        })
      }
    })
  }
  private displayLog = () => {
    let txtColor = 'white'
    let isRawLogs = false
    let logSource: any = null
    const logsByBlocks = this.state.logsByBlocks

    switch (this.state.selectedLogDisplayMode) {
      case RAW_LOGS:
        logSource = this.state.logsByLines
        isRawLogs = true
        break;
      case FORMATTED_LOGS:
        logSource = this.state.parserText
        break;
      default:
        const match = display_mode_matcher.exec(this.state.selectedLogDisplayMode)
        if (match) {
          let pageNumber = match[1]
          logSource = logsByBlocks[(pageNumber as keyof typeof logsByBlocks)]
        }
        break;
    }
    let rowCount = 0
    return logSource.map((item: any, index: number) => {
      let displayString = item
      let isHtml = false
      if (!isRawLogs) {
        txtColor = item.color
        displayString = item.displayString
        isHtml = item.isHtml
      }
      if (isHtml) {
        return (
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ minWidth: '40px' }}></div>
            <TableContainer dangerouslySetInnerHTML={{ __html: displayString }}>
            </TableContainer>
          </div>
        )
      } else {
        rowCount = rowCount + 1
        return (
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ minWidth: '40px' }}>{rowCount}.</div>
            <div style={{ lineHeight: 1.6, color: txtColor }}>{displayString}</div>
          </div>
        )
      }
    })
  }
  private downloadLogFile() {
    let file = new Blob([this.state.logs], { type: 'text/plain' });
    let aTag = document.createElement("a");
    document.body.appendChild(aTag);
    let blobURL = aTag.href = URL.createObjectURL(file);
    aTag.download = 'parser.log';
    aTag.dispatchEvent(new MouseEvent("click"));
    document.body.removeChild(aTag);
    URL.revokeObjectURL(blobURL);
  }

  public render() {
    // @ts-ignore
    const hasClipboardApi = navigator.clipboard
    const toasterOptions = { duration: 2, id: 'unique-toaster' }
    return (
      <>
        <TitleCard
          title={'Parser Log'}
          icon={'comparison'}
          actionComponent={
            <table>
              <tr>
                <td>
                  <Combobox
                    initialSelectedItem={FORMATTED_LOGS}
                    items={this.state.logDisplayModes}
                    onChange={(selectedValue: any) => {
                      this.setState({ selectedLogDisplayMode: selectedValue })
                    }
                    }
                  />
                </td>
                <td>
                  <Button
                    iconBefore={DownloadIcon}
                    appearance="minimal"
                    onClick={() => this.downloadLogFile()}>
                    Download Log
                  </Button>
                </td>
                <td>
                  {hasClipboardApi && (
                    <Button
                      iconBefore={'clipboard'}
                      appearance="minimal"
                      onClick={() => {
                        // @ts-ignore
                        navigator.clipboard
                          .writeText(this.state.logs)
                          .then(() => toaster.success('Copied!', toasterOptions))
                          .catch(() => toaster.danger('Failed to Copy', toasterOptions))
                      }}
                    >
                      Copy
                    </Button>
                  )}
                </td>
              </tr>
            </table>
          }
        >
          <Container>{this.displayLog()}</Container>
        </TitleCard>
      </>
    )
  }
}

export default ParamsLogContainer

const Container = styled.div`
  background-color: ${colors.black};
  overflow: auto;
  padding: 1rem;
  margin: -12px -24px -24px -24px;
  font-size: 0.9rem;
  line-height: 1.2rem;
  position: relative;
  z-index: 2;
  border-radius: 0 0 5px 5px;
  color: rgba(255, 255, 255, 0.9);
  font-family: monospace, monospace;
`
const TableContainer = styled.div`
  table, th, td {
    border: 1px solid blue;
  }
  th, td {
    padding: 8px 16px;
    white-space: nowrap; 
  }
`
