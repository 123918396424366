import { getApi } from '../utils/api/Api'
import {cleanInputFile} from '../utils/FileUtils'
import { FilePath } from './api-constants'
import { getAuthorizationHeaders } from './auth-api'

const { toaster } = require('evergreen-ui')
export type fileType = 'input_pdf'| 'input_processed_pdf' | 'output_excel' | 'output_json' | 'canopy_ul'|'input_processed_pdf_html'|'bank_config'
const axios = require('axios')

export const downloadFileCore = async (fileId: string,  type: fileType, history_uuid:string|null = null, exclude_images:boolean|null = null) => {
  let api_url = null
  let api_method = null
  let api_get_param = null
  let api_post_data = null
  if (type!='input_processed_pdf_html' ){
    api_get_param = {'parse_id': fileId, 'file_type': type}
    api_url = getApi(FilePath.downloadFile)
  }else{
    api_method = 'post'
    api_post_data = new FormData()
    if(exclude_images) api_post_data.set('exclude_images', exclude_images.toString())
    if(history_uuid) api_post_data.set('history_uuid', history_uuid)
    api_url = getApi(FilePath.downloadHTMLFile)
  }
  return axios({
    method: api_method,
    url: api_url,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...await getAuthorizationHeaders()
    },
    params: api_get_param,
    data:api_post_data,
    responseType: 'blob',
  })

}
export const downloadFile = async (fileId: string, fileName: string, type: fileType, openFile: boolean = false, history_uuid:string|null = null, exclude_images:boolean|null = null) => {
  let api_url = null
  let api_method = null
  let api_get_param = null
  let api_post_data = null
  if (type!='input_processed_pdf_html' ){
    api_get_param = {'parse_id': fileId, 'file_type': type}
    api_url = getApi(FilePath.downloadFile)
  }else{
    api_method = 'post'
    api_post_data = new FormData()
    if(exclude_images) api_post_data.set('exclude_images', exclude_images.toString())
    if(history_uuid) api_post_data.set('history_uuid', history_uuid)
    api_url = getApi(FilePath.downloadHTMLFile)
  }
  return downloadFileCore(fileId,type,history_uuid,exclude_images)
    .then((res: any) => {
      const file = decodeURI(res.headers['content-disposition'])
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(file)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
      const blob = new Blob([res.data], { type: res.headers['content-type'] })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      if (openFile) {
          link.target = "_blank"
      }
      else {
          link.download = cleanInputFile(fileName)
      }
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((e: any) => {
      const error =
        e.response && e.response.data ? JSON.parse(e.response.data) : 'Unable to download file'
      toaster.danger(error['error'])
    })
}

axios.interceptors.response.use(
  (response: any) => {
    return response
  },
  (error: any) => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') != -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          error.response.data = reader.result
          resolve(Promise.reject(error))
        }

        reader.onerror = () => {
          reject(error)
        }

        reader.readAsText(error.response.data)
      })
    }

    return Promise.reject(error)
  },
)
