import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
const { Button, Heading } = require('evergreen-ui')
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { downloadFileCore, fileType } from '../../api/file-downloader-api'
import {convertFileTypeToItemType, getDisplayFieldByItemType, responseInfo} from './utils'
import TaskItem from './TaskItem'
import ResponseItem from './ResponseItem'
import { colors } from '../../utils/colors'
import {cleanInputFile, FILE_NOT_AVAILABLE} from '../../utils/FileUtils'




class DisplayDownloadTask extends React.Component<any> {
  public state = {
    taskResponseData: [],
    isLoading: false,
    count: 0,
    bankName: '',
    subtype: '',
  }
  private displayTable = () => {
    return this.props.bulkData.map((item: any) => {
      return <TaskItem item={item} itemType={this.props.location} />
    })
  }

  private displayResponse = () => {
    return this.state.taskResponseData.map((reponseItem: responseInfo) => {
      return <ResponseItem item={reponseItem} itemType={this.props.location} />
    })
  }
  private responseHeader = () => {
    return (
      <Header>
        <div className="fileName">File Name</div>
        <div className="status">Status</div>
        <div>Message</div>
      </Header>
    )
  }
  private pdfDownload = () => {
    this.downloadFile('input_pdf')
  }
  private excelDownload = () => {
    this.downloadFile('output_excel')
  }
  private canopyDownload = () => {
    this.downloadFile('canopy_ul')
  }
  private zipName(type: any) {
    if (type === 'input_pdf') {
      return 'canopy' + '-' + 'pdf'
    } else if (type === 'output_excel') {
      return 'canopy' + '-' + 'excel'
    } else {
      return 'canopy' + '-' + 'ul'
    }
  }
  private onfileDownloaded(item:any, processCount:number, isSuccess:boolean, message:string, zip:JSZip, zipFile:string, taskResponseData: any[]): void{
    
    const temp = {
      item: item,
      success: isSuccess,
      message: message,
    }
    taskResponseData.push(temp)
    this.setState({
      taskResponseData: taskResponseData,
    })
    this.setState({
      isLoading: false,
    })
    const successCount = Object.keys(zip.files).length
    this.setState({
      count: processCount
    })
    if (processCount == this.props.bulkData.length && successCount >0) {
      zip
        .generateAsync({
          type: 'blob',
        })
        .then((content: any) => {
          saveAs(content, zipFile + '.zip')
        })
    }
  }

  private downloadFile = async (fileType:fileType) => {
    var cancelResponseData: any[] = []
    var processCount = 0
    var zip: any = null
    zip = new JSZip()
    const zipFile = this.zipName(fileType)
    this.setState({
      isLoading: true,
    })
    const itemType = convertFileTypeToItemType(fileType)
    for (let item of this.props.bulkData) {
      const displayInfo = getDisplayFieldByItemType(item, itemType)

      if(displayInfo.isAvailable){
        var fileName = displayInfo.field
        downloadFileCore(item.fileUuid, fileType)
        .then((res: any) => {
          const file = decodeURI(res.headers['content-disposition'])
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(file)
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '')
          }
          zip.file(cleanInputFile(fileName), res.data, {
            binary: true,
          })
          processCount = processCount + 1
          this.onfileDownloaded(item, processCount, true, 'successful', zip, zipFile, cancelResponseData)
        })
        .catch((e: any) => {
          const error =
            e.response && e.response.data ? JSON.parse(e.response.data) : 'Unable to download file'
          processCount = processCount + 1
          this.onfileDownloaded(item, processCount, false, error.error, zip, zipFile, cancelResponseData)
        })
      }
      else{
        processCount = processCount + 1
        this.onfileDownloaded(item, processCount, false, FILE_NOT_AVAILABLE, zip, zipFile, cancelResponseData)
      }
    }
  }
  
  private downLoadAll = () => {
    this.downloadFile('input_pdf')
    this.downloadFile('output_excel')
    this.downloadFile('canopy_ul')
  }
  private displayProcessButton = () => {
    const { isPdfDownload, isExcelDownload, isCanopyDownload, isDownloadAll } = this.props.location
    const anyFileAvailable = this.props.bulkData.some((item: any)=>{
      const displayInfo = getDisplayFieldByItemType(item, this.props.location)
      return displayInfo.isAvailable
    })
    if(anyFileAvailable){
      if (isPdfDownload) {
        return (
          <Button onClick={this.pdfDownload} appearance="primary" isLoading={this.state.isLoading}>
            PDF DOWNLOAD
          </Button>
        )
      } else if (isExcelDownload) {
        return (
          <Button onClick={this.excelDownload} appearance="primary" isLoading={this.state.isLoading}>
            Excel DOWNLOAD
          </Button>
        )
      } else if (isCanopyDownload) {
        return (
          <Button onClick={this.canopyDownload} appearance="primary" isLoading={this.state.isLoading}>
            Canopy UL DOWNLOAD
          </Button>
        )
      } else if (isDownloadAll) {
        return (
          <Button onClick={this.downLoadAll} appearance="primary" isLoading={this.state.isLoading}>
            {`PDF & Excel & Canopy UL download `}
          </Button>
        )
      }
    }
  }
  private displayTableHeader() {
    return (
      <Header>
        <div className="fileName">File Name</div>
        <div>Status</div>
      </Header>
    )
  }
  private displyResponseDataTable() {
    if (this.state.taskResponseData.length > 0) {
      return (
        <>
          {this.responseHeader()}
          {this.displayResponse()}
        </>
      )
    } else if (this.props.bulkData.length > 0) {
      return (
        <>
          {this.displayTableHeader()}
          {this.displayTable()}
          {this.displayProcessButton()}
        </>
      )
    } else {
      return null
    }
  }
  public render() {
    const totalCount = this.props.bulkData.length
    return (
      <Container>
        <div className="cardHeader">
          <Heading size={700} color={colors.blue} className="title">
            Download List
          </Heading>
          <Heading size={700} color={colors.blue} className="count" marginBottom={10}>
            <span>Completed:</span>
            {this.state.count} of {totalCount}
          </Heading>
        </div>
        {this.displyResponseDataTable()}
      </Container>
    )
  }
}
const mapStateToProps = (storeData: any) => {
  return {
    bulkData: storeData.onCheckReducer.bulkOperationData,
    params: storeData.onChangeReducer.params,
  }
}

const Container = styled.div`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  background-color: white;
  .cardHeader {
    border-bottom: 2px solid #f5f5f5;
    display: flex;
    .title {
      width: 80%;
    }
    .count {
      span {
        padding-right: 10px;
      }
    }
  }
`
const Header = styled.div`
  display: flex;
  background-color: #f5f5f5;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  .fileName {
    width: 50%;
  }
  .status {
    width: 10%;
  }
  .;
`
export default connect(mapStateToProps, null)(DisplayDownloadTask)
